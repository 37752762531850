@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
html, body {
  box-sizing: border-box;
  font-size: 62.5%;
  background-color: silver;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5;
}

h3 {
  font-size: 2rem;
}

.title {
  color: white;
}

#about, #work, #contact {
  width: 100%;
  display: flex;
  text-align: center;
}

#about div, #work div, #contact div {
  width: 50%;
}

.titleDiv {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleDiv nav {
  margin-top: 35%;
}

.titleImg {
  width: 300px;
  margin-top: 30%;
}

/* Nav Styles */
nav {
  font-weight: bold;
  color: firebrick;
  text-transform: uppercase;
}

nav a {
  text-decoration: none;
  font-weight: bold;
  color: firebrick;
  margin: 2% 2% 0;
}

nav a:hover {
  color: white
}

/* Home Page Styles */
.homeDiv {
  height: 100vh;
  margin: 2% 0;
  text-transform: uppercase;
  color: firebrick;
}

.homeDiv nav {
  margin-top: 5%;
}

.ms {
  width: 55%;
}

.elephants {
  width: 20%;
  margin-top: 1%;
}

.homeDiv h4 {
  margin-top: 0;
}

/* About Styles */
.aboutImgDiv {
  background-color: firebrick;
}

.aboutImgDiv nav, .aboutImgDiv nav a {
  color: black;
}

.aboutImgDiv nav a:hover {
  color: white;
}

.aboutDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-top: 3%;
  background-color: white;
}

.aboutDiv p, .aboutDiv h3 {
  padding: 0 10%;
}

.skillsh3 {
  margin-bottom: 0;
}

.skills {
  padding-top: 0;
}

.michelle {
  width: 25%;
  border-radius: 5px;
  border: 2px solid firebrick;
}

/* Work Styles */
.projectDiv {
  height: 100vh;
  overflow: auto;
  background-color: firebrick;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectDiv div {
  padding-bottom: 3%;
}

.projectDiv::-webkit-scrollbar {
  display: none;
}

.projectDiv a {
  text-decoration: none;
}

.ddt, .gigapet, .farmfresh, .amiibo, .life, .dream, .rise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.life {
  margin-top: 5%;
}

.rise h3, .rise p, .dream h3, .dream p, .life h3, .life p, .ddt h3, .ddt p, .gigapet h3, .gigapet p, .farmfresh h3, .farmfresh p, .amiibo h3, .amiibo p {
  width: 175%;
  text-align: left;
  color: white;
}

li {
  width: 175%;
  text-align: left;
  color: white;
}

.links {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}

.links a {
  margin: 0 8%;
}

.links i {
  color: white;
  font-size: 1.6em;
}

.links i:hover {
  color: black;
}

.rise img, .ddt img, .gigapet img, .farmfresh img, .amiibo img, .life img, .dream img {
  width: 100%;
}

.border {
  border-top: 5px dotted black;
}

/* Contact Styles */
.contactDiv {
  /* height: 95vh; */
  background-color: #504c4c;
}

.contactDiv nav, .contactDiv nav a {
  color: black;
}

.contactDiv nav a:hover {
  color: white;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 3%;
}

.contactText, .iconLinks {
  margin-left: 10%;
}

.contactText h2, .contactText p {
  width: 175%;
  margin: 10% 0;
}

#contact div.iconLinks {
  display: flex;
  justify-content: space-between;
  margin-top: 10%;
  width: 75%;
}

.iconLinks a {
  text-decoration: none;
  color: black;
}

.iconLinks i {
  color: black;
  font-size: 3em;
}

.iconLinks i:hover {
  color: firebrick;
}

/* footer styles */
footer {
  width: 100%;
  background-color: firebrick;
  color: white;
}

/* Media Queries */
/* 1280px */
@media (max-width: 1280px) {
  .homeDiv {
    margin-top: 5%;
    height: 90vh;
  }

  .homeDiv nav {
    margin-top: 8%;
  }
  
}

/* 1200px*/
@media (max-width: 1200px) {
  .homeDiv nav {
    margin-top: 10%;
  }
}

@media (max-width: 1100px) {
  .homeDiv {
    margin-top: 7%;
  }

  .homeDiv nav {
    margin-top: 13%;
  }

  .titleDiv nav {
    margin-top: 40%;
  }
}

/* iPad Pro 1024 */
@media (max-width: 1024px) {

  .homeDiv {
    margin-top: 5%;
    height: 80vh;
  }

  .homeDiv nav {
    margin-top: 5%;
  }

  .titleDiv, .projectDiv, .contactDiv {
    height: 95vh;
  }

  .titleDiv nav {
    margin-top: 30%;
  }

  .aboutDiv p, .aboutDiv h3 {
    padding-right: 15%;
    padding-left: 8%;
  }

  h3 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.2rem;
  }

  li, .rise h3, .rise p, .dream h3, .dream p, .life h3, .life p, .ddt h3, .ddt p, .gigapet h3, .gigapet p, .farmfresh h3, .farmfresh p, .amiibo h3, .amiibo p {
    width: 155%;
    text-align: left;
    margin-left: 0;
  }

  .contactText h2, .contactText p {
    width: 150%;
    margin: 10% 0;
  }

}

/* 900px */
@media (max-width: 900px) {
  .homeDiv {
    height: 80vh;
  }

  .homeDiv nav {
    margin-top: 8%;
  }

  .titleDiv, .projectDiv, .contactDiv {
    height: 95vh;
  }

  .titleDiv nav {
    margin-top: 40%;
  }

  h3 {
    font-size: 1.4rem;
  }

  p, li {
    font-size: 1.2rem;
  }

  li, .rise h3, .rise p, .dream h3, .dream p, .life h3, .life p, .ddt h3, .ddt p, .gigapet h3, .gigapet p, .farmfresh h3, .farmfresh p, .amiibo h3, .amiibo p {
    width: 165%;
    text-align: left;
    margin-left: 0;
  }
}

/* iPad 768*/
@media (max-width: 768px) {
  .homeDiv {
    height: 70vh;
  }

  .homeDiv nav {
    margin-top: 10%;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1rem;
  }

  .aboutImgDiv, .titleDiv {
    height: 85vh;
  }

  .aboutDiv p, .aboutDiv h3 {
    padding-right: 15%;
  }

  .iconLinks i {
    font-size: 2.4em;
  }

}

@media (max-width: 600px) {
  .homeDiv {
    height: 60vh;
    margin-top: 8%;
  }

  .homeDiv h4, .homeDiv nav {
    font-size: 1.4rem;
  }

  #about, #work, #contact {
    flex-direction: column;
  }
  
  #about div, #work div, #contact div {
    width: 100%;
  }

  .rise img, .dream img, .ddt img, .gigapet img, .farmfresh img, .amiibo img, .life img{
    width: 80%
  }

  li, .rise h3, .rise p, .dream h3, .dream p, .life h3, .life p, .ddt h3, .ddt p, .gigapet h3, .gigapet p, .farmfresh h3, .farmfresh p, .amiibo h3, .amiibo p {
    width: 90%;
    margin-left: 5%;
  }

  .life, .ddt, .gigapet, .farmfresh, .amiibo, .dream, .rise {
    padding-bottom: 2%;
  }

  .titleDiv nav {
    display: none;
  }

  .titleImg {
    margin-top: 0;
  }

  .contactText {
    width: 100%;
    margin-left: 5%;
  }

  .contactText h2, .contactText p {
    width: 100%;
    margin: 5% 0;
  }

  .iconLinks {
    justify-content: space-evenly;
    margin: 0 0 5% 5%;
  }

  .iconLinks i {
    font-size: 2.2em;
  }
}

/* Note 10 Plus */
@media (max-width: 500px) {
  .homeDiv {
    height: 45vh;
  }

  .ms {
    width: 70%;
  }

  #about .aboutImgDiv, #work .titleDiv, #contact .contactDiv, .contactInfo {
    height: 50vh;
  }

  .aboutDiv p, .aboutDiv h3 {
    padding-right: 15%;
  }

  .contactText {
    padding: 3% 0 5%;
  }

  .contactText h2, .contactText p {
    width: 80%;
  }

  .contactText p {
    font-size: 1.2rem;
  }

  .iconLinks i {
    font-size: 2em;
  }
}

@media (max-width: 360px) {
  .homeDiv {
    height: 40vh;
  }

  .homeDiv h4, .homeDiv nav {
    font-size: 1rem;
  }

  .homeDiv nav {
    margin-top: 12%;
  }

  .titleImg {
    width: 275px;
  }

  #about .aboutDiv {
    height: 100%;
  }

  .contactInfo {
    height: 40vh;
  }

  div.iconLinks {
    margin-left: 8%;
  }

  .iconLinks i {
    font-size: 1.8em;
  }

  footer {
    font-size: 1.4rem;
  }
}

